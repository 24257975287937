import { useTranslation } from 'react-i18next'

import useHomeHooks, {
	HomeListBtnActiveClass,
	HomeListBtnNoColor,
	HomeListColor,
	TaskInviteNumberTotal,
	TaskInviteTypeTitle,
} from '@/hooks/useHomeHooks'
import { cnTw } from '@/utils'
import { Button, Image } from '@nextui-org/react'
import HomeBossModule from './components/Boss'

import BaseModalCenter from '@/components/ModalCenter'
import { useEffect, useMemo, useRef, useState } from 'react'

import { ReactComponent as TASK_INOUT } from '@/assets/home/task-input.svg'
import BIBI from '@/assets/bibi.png'
import { ReactComponent as TEXTSVG } from '@/assets/text.svg'
import useBalance from '@/lib/contract/useBalance'
import { multicall } from '@/lib/web3/multicall'
import { DEFAULT_CHAINID } from '~/config/*'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import getChainConfig from '@/lib/web3/getChainConfig'
import { POTA } from '@/contract/abi'
import { encodeFunctionData, formatEther, isAddress, zeroAddress } from 'viem'
import toast from 'react-hot-toast'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
// import { Signature } from 'ethers'
import { useNavigate, useSearchParams } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { bindModal } from '@/components/BindModal'
import useNotifyHooks from '@/components/Banner/hooks/useNotifyHooks'
import useApproveHooks from '@/hooks/useApproveHooks'

type IStateLoad = {
	keys: number
	loading: boolean
}

const StateLoadInit: IStateLoad = {
	keys: -1,
	loading: false,
}

export default function HomePage() {
	const navigate = useNavigate()
	// 邀请的地址
	const [params] = useSearchParams()
	const inviteParams = params.getAll('invite')[0]

	const TaskModalRef = useRef<any>(null)
	// 当前点击的任务进度的模式
	const [levelId, setLevelId] = useState<number>(0)
	// 点击激活时候的loading
	const [stateLoad, setStateLoad] = useState<IStateLoad>(StateLoadInit)

	// modal 绑定/领取
	const [modalLoading, setModalLoading] = useState<boolean>(false)

	const { t } = useTranslation()
	const { isConnected, address } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()
	const { contracts } = getChainConfig()
	const POTA_ADDRESS = contracts['POTA_ADDRESS']
	const TOKEN_ADDRESS = contracts['TOKEN_ADDRESS']

	const { onSendTransaction, onApproveUsdt } = useSendTransaction()

	const { list, userInfo } = useHomeHooks().data
	const { tokens, main } = useBalance().data

	const { mutate: mutateBalance } = useBalance()
	const { mutate: mutateHome, isLoading, isValidating } = useHomeHooks()
	const homeLoading = isLoading || isValidating

	const { mutate: mutateNotify } = useNotifyHooks()
	
	const { isLevelArr } = useApproveHooks().data
	const {mutate: mutateApprove} = useApproveHooks()

	// 是否已绑定
	const isBinds = useMemo(() => {
		return userInfo.inviter !== zeroAddress ? true : false
	}, [userInfo.inviter])

	useEffect(() => {
		handleBindModal()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inviteParams, isBinds, homeLoading, isConnected])

	const handleBindModal = () => {
		if (!isBinds && !homeLoading && isConnected) {
			if (inviteParams && typeof inviteParams === 'string' && isAddress(inviteParams)) {
				bindModal({
					onDone: () => {
						navigate('/home', {
							replace: true,
						})
					},
					addressInvite: inviteParams,
				})
			}
		}
	}

	useEffect(() => {
		console.log('tokens', tokens, 'main', main)
		console.log('userInfo', userInfo)
	}, [main, tokens, userInfo])

	// 授权USDT
	const handleApproveUSDT = async (keys: number) => {
	  if (!isConnected || !address) {
			open()
			return
		}
		setStateLoad({ keys, loading: true })
		const minAmount = await multicall(DEFAULT_CHAINID, {
			contracts: [
				{
					address: POTA_ADDRESS,
					abi: POTA,
					functionName: 'levelDepositAmounts',
					args: [keys],
				},
			],
		}).then(res => {
			return res[0].status === 'success' ? formatEther(BigInt(`${res[0].result || 0}`)) : 0
		})
		const tokenAmount = tokens['TOKEN_ADDRESS']
		if (Number(main) === 0) {
			toast.error(t('home.toast.balance.zero'), { id: 'depositError' })
			setStateLoad(StateLoadInit)
			return
		}
		if (Number(minAmount) > Number(tokenAmount)) {
			toast.error(t('home.toast.mix.amount', { amount: tokenAmount }), { id: 'depositError' })
			setStateLoad(StateLoadInit)
			return
		}
		console.log('minAmount', minAmount, 't', tokens['TOKEN_ADDRESS'])
		try {
			await onApproveUsdt({
				to: POTA_ADDRESS,
				usdtAddress: TOKEN_ADDRESS,
			})
			toast.success(t('home.toast.approve.success'), { id: 'approveSuccess' })
			mutateApprove()
			setStateLoad(StateLoadInit)
		} catch (error: any) {
			toast.error(error.message || error || t('home.toast.approve.error'), {id: 'approveError'})
			setStateLoad(StateLoadInit)
		}
	}

	// 开启关卡
	const handleOpenClose = async (keys: number) => {
		if (!isConnected || !address) {
			open()
			return
		}
		setStateLoad({ keys, loading: true })
		const minAmount = await multicall(DEFAULT_CHAINID, {
			contracts: [
				{
					address: POTA_ADDRESS,
					abi: POTA,
					functionName: 'levelDepositAmounts',
					args: [keys],
				},
			],
		}).then(res => {
			return res[0].status === 'success' ? formatEther(BigInt(`${res[0].result || 0}`)) : 0
		})
		const tokenAmount = tokens['TOKEN_ADDRESS']
		if (Number(main) === 0) {
			toast.error(t('home.toast.balance.zero'), { id: 'depositError' })
			setStateLoad(StateLoadInit)
			return
		}
		if (Number(minAmount) > Number(tokenAmount)) {
			toast.error(t('home.toast.mix.amount', { amount: tokenAmount }), { id: 'depositError' })
			setStateLoad(StateLoadInit)
			return
		}
		console.log('minAmount', minAmount, 't', tokens['TOKEN_ADDRESS'])
		// const nonces = await handleQueryNonce({
		// 	address,
		// 	TOKEN_ADDRESS,
		// })
		// console.log('nonces', nonces)
		// const dataSign = await onErc20SignTypeData({
		// 	token: TOKEN_ADDRESS,
		// 	owner: address,
		// 	spender: POTA_ADDRESS,
		// 	amount: parseEther(minAmount.toString()),
		// 	nonce: nonces as bigint,
		// })
		// if (typeof dataSign !== 'object') {
		// 	toast.error(t('home.toast.sign.error'), { id: 'depositError' })
		// 	setStateLoad(StateLoadInit)
		// 	return
		// }
		try {
			// const signData = Signature.from(dataSign?.sign)
			// const { v, r, s } = signData
			// console.log('dataSign', dataSign)
			// console.log('v,r,s', v, r, s)
			let abiData = [keys+1]
			console.log('abiData', abiData)
			const data = encodeFunctionData({
				abi: POTA,
				functionName: 'deposit',
				args: abiData,
			})
			await onSendTransaction({
				data: data,
				to: POTA_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			toast.success(t('home.toast.deposit.success'), { id: 'depositSuccess' })
			mutateBalance()
			mutateHome()
			setStateLoad(StateLoadInit)
		} catch (error: any) {
			toast.error(error.message || error || t('home.toast.deposit.error'), { id: 'depositError' })
			console.log('openClose error', error)
			setStateLoad(StateLoadInit)
		}
	}

	// 领取奖励
	const handleClaim = async () => {
		if (!isConnected || !address) {
			open()
			return
		}
		setModalLoading(true)
		if (Number(main) === 0) {
			toast.error(t('home.toast.balance.zero'), { id: 'bindError' })
			setModalLoading(false)
			return
		}

		try {
			let abiData = [levelId + 1]
			console.log('abiData', abiData)
			const data = encodeFunctionData({
				abi: POTA,
				functionName: 'claimReward',
				args: abiData,
			})
			await onSendTransaction({
				data: data,
				to: POTA_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			toast.success(t('home.toast.claim.success'), { id: 'claimRewardSuccess' })
			mutateBalance()
			mutateHome()
			mutateNotify()
			setModalLoading(false)
			TaskModalRef.current?.onClose()
		} catch (error: any) {
			toast.error(error.message || error || t('home.toast.claim.error'), { id: 'claimRewardError' })
			setModalLoading(false)
		}
	}

	return (
		<>
			<div className="flex flex-col items-center gap-4 px-4 pb-10 sm:h-full sm:overflow-hidden sm:overflow-y-scroll">
				{list.map((card, key) => (
					<div key={key} className="w-full">
						<div className="relative flex w-full flex-col items-center">
							{card.bgCard}
							<div
								className="relative z-10 mt-1 text-lg font-bold"
								style={{
									color: HomeListColor[key % 4],
								}}
							>
								{t(card.title)}
							</div>
							<div className={cnTw(card.btnStatus === 'Go' && 'mb-3 mt-3')}>{card.imageIcon}</div>
							<div
								onClick={() => {
									setLevelId(key)
									TaskModalRef.current?.onOpen()
								}}
								className={cnTw('relative z-10 -mt-1 mb-1 hidden h-5', card.btnStatus === 'Invite' && 'block')}
							>
								<span className="text-xs leading-5 underline">{t('home.check.point.title')}</span>
							</div>
							<Button
								isDisabled={!card.isOpen || stateLoad.loading}
								isLoading={stateLoad.loading && key === stateLoad.keys}
								onClick={() => {
									if (!isConnected || !address) {
										open()
										return
									}
									if (card.btnStatus === 'Go') {
										if (!isLevelArr[key]) {
											handleApproveUSDT(key)
										} else handleOpenClose(key)
									}
									else {
										copy(`${window.location.origin}/#/home?invite=${address}`)
										toast.success(t('home.toast.copy.success'), { id: 'copyInviteSuccess' })
									}
								}}
								className={cnTw(
									'relative z-40 mt-1 h-10 min-w-[9.5625rem] rounded-[0.3125rem]',
									card.isOpen && HomeListBtnActiveClass[key % 4],
									'disabled:opacity-100',
								)}
								style={{
									background: card.isOpen ? '' : HomeListBtnNoColor[key % 4],
								}}
							>
								<span className="text-base font-bold text-[#333]">
									{card.btnStatus === 'Invite' ? t('home.btn.1') : 
									isLevelArr[key] ? t('home.btn.2') :
									!card.isOpen ? t('home.btn.2'):
									t('home.btn.3')}
								</span>
							</Button>
						</div>
						<div className="tail-box-line mt-4"></div>
					</div>
				))}
				<HomeBossModule />
			</div>

			<BaseModalCenter
				onDone={handleClaim}
				level={levelId + 1}
				loading={modalLoading}
				title={t('home.modal.title2')}
				ref={TaskModalRef}
			>
				<div className="relative z-20 flex flex-1 flex-col px-3">
					<div className="mt-4 flex flex-col gap-3">
						{userInfo.downLineCounts[levelId].map((item, index) => (
							<div key={index} className="relative flex h-12 w-full items-center">
								<TASK_INOUT className="absolute z-0" />
								<div className="relative z-10 ml-3 flex h-10 w-10 items-center justify-center rounded-full">
									<TEXTSVG className="absolute left-0 top-0" />
									<span className="relative -ml-0.5 -mt-1 text-base font-bold text-color-text-theme">{index + 1}</span>
								</div>
								<span className="relative z-10 ml-1 mr-4 text-xs text-[#999999]">
									{t((TaskInviteTypeTitle as any)[`KEY${index}`])}
								</span>
								<div className="relative z-10 flex items-center text-base text-[#4AFCDD]">
									{item}/{TaskInviteNumberTotal[userInfo.exitCounts[levelId] < 3 ? userInfo.exitCounts[levelId] : 2][index]}
								</div>
								<div
									className={cnTw(
										'absolute right-3 z-10 hidden',
										item >= TaskInviteNumberTotal[userInfo.exitCounts[levelId] < 3 ? userInfo.exitCounts[levelId] : 2][index] &&
											'block',
									)}
								>
									<Image src={BIBI} className="h-6 w-6 rounded-full" />
								</div>
							</div>
						))}
					</div>
				</div>
			</BaseModalCenter>
		</>
	)
}
