import { ReactComponent as BgTopLeft } from '@/assets/svg/bg-top-left.svg'
import { ReactComponent as BgBanner } from '@/assets/svg/bg-banner.svg'
import { ReactComponent as TEXT } from '@/assets/layout/text.svg'
import { ReactComponent as LINE } from '@/assets/layout/line.svg'

import BG_BOOTOM from '@/assets/layout/bg-bottom.png'
import BG_TOP from '@/assets/layout/bg-top.png'

import { useTranslation } from 'react-i18next'
import { bindModal } from '../BindModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'

export default function LayoutBGModule() {
	const { t } = useTranslation()
	// 邀请的地址
	const [params] = useSearchParams()
	const inviteParams = params.getAll('invite')[0]

	const { isConnected } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()

	const navigate = useNavigate()
	return (
		<>
			<div className="absolute left-0 top-[4.375rem] flex w-full items-center justify-center">
				<TEXT />
			</div>
			<div
				onClick={() => {
					if (!isConnected) {
						open()
					} else {
						bindModal({
							onDone: () => {
								navigate('/home', {
									replace: true,
								})
							},
							addressInvite: inviteParams || '',
						})
					}
				}}
				className="absolute left-0 top-[23.125rem] z-[45] flex w-full items-center justify-center"
			>
				<LINE />
				<div className="absolute text-base font-bold text-color-text-theme">{t('home.modal.title1')}</div>
			</div>

			<BgTopLeft className="absolute left-0 top-0 sm:rounded-tl-2xl" />
			<BgBanner className="absolute left-0 top-[6.25rem] w-full" />
			<img
				src={BG_BOOTOM}
				alt=""
				width={375}
				height={926}
				className="absolute bottom-0 left-0 z-0 h-auto w-full sm:rounded-b-2xl"
			/>
			<img src={BG_TOP} alt="" width={375} height={475} className="absolute left-0 top-0 z-0 h-auto w-full" />
		</>
	)
}
