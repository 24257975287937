import { AllowanceUSDT, POTA } from "@/contract/abi"
import getChainConfig from "@/lib/web3/getChainConfig"
import { multicall } from "@/lib/web3/multicall"
import { useWeb3ModalAccount } from "@web3modal/ethers/react"
import useSWR from "swr"
import { formatEther } from "viem"
import { DEFAULT_CHAINID } from '~/config/*'

export default function useApproveHooks() {
  const { isConnected, address } = useWeb3ModalAccount()
  const { contracts } = getChainConfig()
	const TOKEN_ADDRESS = contracts['TOKEN_ADDRESS']
	const POTA_ADDRESS = contracts['POTA_ADDRESS']

  const fetcher = async () => {
	return await multicall(DEFAULT_CHAINID, {
    contracts: [
      {
        address: TOKEN_ADDRESS,
        abi: [AllowanceUSDT],
        functionName: 'allowance',
        args: [address, POTA_ADDRESS],
      },
      {
        address: POTA_ADDRESS,
        abi: POTA,
        functionName: 'levelDepositAmounts',
        args: [0],
      },
      {
        address: POTA_ADDRESS,
        abi: POTA,
        functionName: 'levelDepositAmounts',
        args: [1],
      },
      {
        address: POTA_ADDRESS,
        abi: POTA,
        functionName: 'levelDepositAmounts',
        args: [2],
      },
      {
        address: POTA_ADDRESS,
        abi: POTA,
        functionName: 'levelDepositAmounts',
        args: [3],
      },
    ],
  }).then(res => {
    return {
      allowance:  Number(formatEther((res[0].status === 'success' ? res[0].result : 0) as bigint)),
      levelMinAmount0: Number(formatEther((res[1].status === 'success' ? res[1].result : 0) as bigint)),
      levelMinAmount1: Number(formatEther((res[2].status === 'success' ? res[2].result : 0) as bigint)),
      levelMinAmount2: Number(formatEther((res[3].status === 'success' ? res[3].result : 0) as bigint)),
      levelMinAmount3: Number(formatEther((res[4].status === 'success' ? res[4].result : 0) as bigint)),
    }
  }).then(res => {
    return {
      allowance: res.allowance,
      levelMinAmount: [res.levelMinAmount0, res.levelMinAmount1, res.levelMinAmount2, res.levelMinAmount3],
      isLevel0AllowanceMinAmount: res.allowance >= res.levelMinAmount0,
      isLevel1AllowanceMinAmount: res.allowance >= res.levelMinAmount1,
      isLevel2AllowanceMinAmount: res.allowance >= res.levelMinAmount2,
      isLevel3AllowanceMinAmount: res.allowance >= res.levelMinAmount3,
    }
  }).then(res => {
    return {
      ...res,
      isLevelArr: [res.isLevel0AllowanceMinAmount, res.isLevel1AllowanceMinAmount, res.isLevel2AllowanceMinAmount, res.isLevel3AllowanceMinAmount]
    }
  })
	}

	const { data, ...args } = useSWR(isConnected ? `useApproveHooks-${address}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})


  return {
    data: {
      usdtAllowance: data?.allowance || 0,
      levelMinAmount: data?.levelMinAmount || [0, 0, 0, 0],
      isLevel0AllowanceMinAmount: data?.isLevel0AllowanceMinAmount || false,
      isLevel1AllowanceMinAmount: data?.isLevel1AllowanceMinAmount || false,
      isLevel2AllowanceMinAmount: data?.isLevel2AllowanceMinAmount || false,
      isLevel3AllowanceMinAmount: data?.isLevel3AllowanceMinAmount || false,
      isLevelArr: data?.isLevelArr || [false, false, false, false],
    },
    ...args
  }
}