import { createPublicClient, http, PublicClient } from 'viem'
import { polygonAmoy as polygonAmoyViem } from 'viem/chains'

const polygonAmoy = {
	...polygonAmoyViem,
	rpcUrls: {
		default: {
			http: ['https://rpc-amoy.polygon.technology'],
		},
	},
}

export const SUPPER_CHAINS = [polygonAmoy]

export const SUPPER_NEXTWORK_NAME = {
	[polygonAmoy.id]: 'polygonAmoy',
}

// Current Supper Chainds
export const CURRENT_SUPPER_CHAINDS = [polygonAmoy.id]

// 当前默认CHAINID
export const DEFAULT_CHAINID = CURRENT_SUPPER_CHAINDS[0]

export type ISwitchNetWrok = {
	chainId: `0x${string}`
	chainName: string
	rpcUrls: string[]
	nativeCurrency: any
	blockExplorerUrls: string[]
}

// 当前切换网络需要的参数
export const switchNetwork: {
	[x: number]: ISwitchNetWrok
} = {
	80002: {
		chainId: `0x${polygonAmoy.id.toString(16)}`,
		chainName: polygonAmoy.name,
		rpcUrls: [polygonAmoy.rpcUrls.default.http[0]],
		nativeCurrency: polygonAmoy.nativeCurrency,
		blockExplorerUrls: [polygonAmoy.blockExplorers.default.url],
	},
}

export enum ContractName {
	TOKEN_ADDRESS = 'TOKEN_ADDRESS',
	POTA_ADDRESS = 'POTA_ADDRESS',
}

type IContractsConfig = {
	[x in ContractName]: `0x${string}`
}
type IOtherConfig = {
	symbol: string
	rpcUrl: string
	explorerUrl: string
}

export type Contracts = IContractsConfig & IOtherConfig

// 合约配置 & 其他信息配置
export const contracts: {
	[x: number]: Contracts
} = {
	80002: {
		TOKEN_ADDRESS: '0x6E5C399A8B7F13Dc07578436Bd738D9f5Af521cC',
		POTA_ADDRESS: '0x80eB8Fe9995e9417B5e9e337B9FC5EA3b57f3CA3',
		symbol: polygonAmoy.nativeCurrency.symbol,
		explorerUrl: polygonAmoy.blockExplorers.default.url,
		rpcUrl: polygonAmoy.rpcUrls.default.http[0],
	},
}

export const tokens = ['TOKEN_ADDRESS']

export const multicall3: {
	[x: number]: {
		publicClient: PublicClient
		multicallAddress: `0x${string}`
	}
} = {
	80002: {
		publicClient: createPublicClient({
			chain: polygonAmoy,
			transport: http(),
		}),
		multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
	},
}
