import { ISendTransation } from './type.d'
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { getBlockTimestamp, getGasPrice, waitForTransaction } from '../web3/multicall'
import BigNumber from 'bignumber.js'
import { DEFAULT_CHAINID } from '~/config/*'

import { polygonAmoy, bsc } from 'viem/chains'
import { REACT_APP_ENV } from '@/utils'
import { createWalletClient, custom, encodeFunctionData } from 'viem'
import ToastError from '../web3/toastError'
import { ethers, MaxUint256 } from 'ethers'
import { ApproveUSDT } from '@/contract/abi'

export const useSendTransaction = () => {
	const { walletProvider } = useWeb3ModalProvider()
	const { address, chainId: linkChainId } = useWeb3ModalAccount()
	const chainId = DEFAULT_CHAINID

	/**
	 * @class onSendTransaction 合约交易方法
	 */
	const onSendTransaction = ({ isLocal = 'en', ...roots }: ISendTransation) => {
		return new Promise(async (resolve, reject) => {
			if (!address || !walletProvider) {
				reject(new Error('on Login'))
				return
			}
			const walletClient = createWalletClient({
				chain: REACT_APP_ENV === 'dev' ? polygonAmoy : bsc,
				transport: custom(walletProvider),
			})

			if (chainId !== linkChainId) {
				try {
					REACT_APP_ENV === 'dev'
						? await walletClient.addChain({ chain: polygonAmoy })
						: await walletClient.addChain({ chain: bsc })
					// REACT_APP_ENV === 'dev'
					// 	? await walletClient.switchChain({ id: polygonAmoy.id })
					// 	: await walletClient.addChain({ chain: polygon })
				} catch (error) {
					console.log('network', error)
					reject('User rejected the request.')
				}
			}
			const tx: any = {
				account: address,
				to: roots.to,
				data: roots.data,
			}
			if (roots.value) tx.value = roots.value
			const gasPrice = await getGasPrice(chainId as number)
			console.log('gasPrice', gasPrice)
			const gasPriceTwo = new BigNumber(Number(gasPrice)).times(2).toString()
			// gas Price tiems 2
			try {
				const hash = await walletClient.sendTransaction({
					...tx,
					gasPrice: new BigNumber(Number(gasPriceTwo)),
					connector: walletProvider,
				})
				if (hash && roots.onTransactionHash) roots.onTransactionHash(hash)
				const result = await waitForTransaction(chainId, hash)
				console.log('result', result)
				resolve(result)
			} catch (error: any) {
				console.log('er', error)
				console.log('ToastError(error)', ToastError(error))
				reject(ToastError(error))
			}
		})
	}

	/**
	 * sign
	 */
	const onErc20SignTypeData = async ({
		token,
		owner,
		spender,
		amount,
		nonce,
	}: {
		token: string
		owner: string
		spender: string
		amount: bigint
		nonce: bigint
	}) => {
		const types = {
			Permit: [
				{ name: 'owner', type: 'address' },
				{ name: 'spender', type: 'address' },
				{ name: 'value', type: 'uint256' },
				{ name: 'nonce', type: 'uint256' },
				{ name: 'deadline', type: 'uint256' },
			],
		}
		const domain = {
			name: 'Token',
			version: '1',
			chainId: DEFAULT_CHAINID,
			verifyingContract: token,
		}
		if (!walletProvider) return null
		const web3 = new ethers.BrowserProvider(walletProvider)

		const lasterTimestamp = await getBlockTimestamp(DEFAULT_CHAINID)
		const txData = {
			owner,
			spender,
			value: amount,
			nonce,
			deadline: BigInt(new BigNumber(Number(lasterTimestamp)).plus(300).toNumber()),
		}
		console.log('txData', txData)

		const signer = await web3.getSigner()

		const sign = await signer.signTypedData(domain, types, txData)
		return {
			sign,
			deadline: txData.deadline,
		}
	}


	/**
	 * Approve usdt
	 */
	const onApproveUsdt = async ({to, usdtAddress}:{
		to: string
		usdtAddress: string
	}) => {
	  let abiData = [to, MaxUint256]
		const data = encodeFunctionData({
			abi: [ApproveUSDT],
			functionName: 'approve',
			args: abiData,
		})
		let receipt = await onSendTransaction({
			data: data,
			to: usdtAddress,
			onTransactionHash: (hash: string) => {
				console.log('Transaction hash:', hash)
			},
		})
		return receipt
	}

	return { onSendTransaction, onErc20SignTypeData, onApproveUsdt }
}
