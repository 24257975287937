const home = {
	'home.check.point.title': 'Check The Progress',
	'home.btn.1': 'Invite Friends',
	'home.btn.2': 'Start Game',
	'home.btn.3': 'Approve',
	'home.modal.title1': 'Bind Address',
	'home.modal.title2': 'Progress Of The Game',
	'home.modal.btn1': 'Confirm',
	'home.modal.btn1_1': 'Bound',
	'home.modal.btn2': 'Reward',
	'home.placeholder.address': 'Please enter the binding address',

	'home.toast.copy.success': 'Invite link copied successfully',
	'home.toast.balance.zero': 'Account balance is zero',
	'home.toast.mix.amount': 'Minimum level pledge amount: {{amount}} U',
	'home.toast.sign.error': 'Signature failed',
	'home.toast.deposit.success': 'Network activation successful',
	'home.toast.deposit.error': 'Pledge failed',
	'home.toast.no.bind.own': 'Cannot bind yourself',
	'home.toast.no.bind.error': 'Address bound',
	'home.toast.bind.success': 'Binding successful',
	'home.toast.bind.error': 'Binding failed',
	'home.toast.claim.success': 'Claim Success',
	'home.toast.claim.error': 'Claim Failed',
	'home.toast.error.address': 'Please enter a correct address',
	'home.toast.error.members': 'The account is not activated',
	'home.toast.approve.error': 'Authorization failed',
'home.toast.approve.success': 'Authorization successful',

	'home.boss.title': 'Hall Of Glory',
	'home.boss.progress': 'Progress',
	'home.boss.tips': 'Automatic collection',
	'home.boss.claimed': '(already issued)',
	'home.boss.nodata': 'Not yet in the Hall of Glory',

	'home.list.1': 'Simple',
	'home.list.2': 'Normal',
	'home.list.3': 'Difficulty',
	'home.list.4': 'Challenge',
	'home.info.1': 'First Floor',
	'home.info.2': 'Second Floor',
	'home.info.3': 'Third Floor',
	'home.info.4': 'Fourth Floor',
	'home.info.5': 'Fifth Floor',
}

export default home
