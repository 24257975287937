import Pota from './abi/Pota.json'
import Token from './abi/Token.json'

export const POTA = Pota as any
export const TOKEN = Token as any


export const ApproveUSDT = {
  "constant": false,
  "inputs": [
    { "internalType": "address", "name": "spender", "type": "address" },
    { "internalType": "uint256", "name": "amount", "type": "uint256" }
  ],
  "name": "approve",
  "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}

export const AllowanceUSDT = {
		"constant": true,
		"inputs": [
			{ "internalType": "address", "name": "owner", "type": "address" },
			{ "internalType": "address", "name": "spender", "type": "address" }
		],
		"name": "allowance",
		"outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
} as any